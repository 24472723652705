import { Box, Button, Heading, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import Ads from 'components/Ads'
import Card from 'components/Card'
import { useOrder } from 'fetchers/cart'
import SuccessLayout from 'layouts/Success'
import OrderInfo from 'scenes/Checkout/components/OrderInfo'
import { formatMoney } from 'utils/currency'

export interface PaymentCompletedProps {
  id: string
}

function PaymentCompleted(props: PaymentCompletedProps) {
  const { id } = props
  const { query: order } = useOrder(id)

  return (
    <SuccessLayout>
      <Heading size='lg' color='white' textAlign='center'>
        <Trans>Order is created!</Trans>
      </Heading>
      <Text color='white' textAlign='center' mt={2}>
        <Trans>
          Thank you for shopping with us, your payment has been verified.
        </Trans>
      </Text>
      <Box>
        {order.data && (
          <>
            <Card p={5} mt={5} textAlign='center'>
              <Text>
                <Trans>Partially Paid</Trans>
              </Text>
              {order.data && order.data.paymentDetails?.total && (
                <Heading size='lg' mt='3' fontWeight='500'>
                  {formatMoney(
                    order.data.paymentDetails?.partiallyPaidAmount || 0
                  )}
                </Heading>
              )}
            </Card>
            <Card p={5} mt={5} textAlign='center'>
              <Text>
                <Trans>
                  Please prepare this amount of cash when receiving your order
                </Trans>
              </Text>
              {order.data && order.data.paymentDetails?.total && (
                <Heading size='lg' mt='3' fontWeight='500'>
                  {formatMoney(
                    order.data.paymentDetails?.total -
                      (order.data.paymentDetails?.partiallyPaidAmount || 0)
                  )}
                </Heading>
              )}
            </Card>
          </>
        )}
        <Card p={5} mt={5}>
          <Text fontWeight='500' color='success'>
            <Trans>Order No. {id}</Trans>
          </Text>

          {order.data && order.data.adsEnabled && (
            <Box mt={2} mb={2} maxWidth={500}>
              <Ads
                description='Hàng Úc xách tay chính hãng, chuyển hàng trực tiếp từ Úc về VN, không qua kho trung gian (voucher giảm 20% đơn hàng đầu tiên - tối đa 200k)'
                url='https://aff.kaio.ai/kajimstore'
              />
            </Box>
          )}

          {order.data && (
            <Box>
              <Card title={<Trans>Shipping Address</Trans>}>
                <Box px={3}>
                  <Text>{order.data.generalInformation?.customerName}</Text>
                  <Text>
                    {order.data.generalInformation?.customerPhoneNumber ?? ''}
                  </Text>
                  <Text>{order.data.deliveryDetails?.deliveryAddress}</Text>
                </Box>
              </Card>

              <OrderInfo data={order.data} />

              <Button
                variant='primary'
                as='a'
                href={`https://www.facebook.com/${order.data.generalInformation?.pageId}`}
                mt={3}
                w='full'
              >
                <Trans>Continue shopping</Trans>
              </Button>
            </Box>
          )}
        </Card>
      </Box>
    </SuccessLayout>
  )
}

export default PaymentCompleted
