import React from 'react'
import { Alert, AlertIcon, Link, Text } from '@chakra-ui/react'
// import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk'
import './styles.css'

interface AdProps {
  description: string
  url: string
}

const Ad: React.FC<AdProps> = ({ description, url }) => {
  // const { checkoutAdsEnabled } = useFlags()
  const checkoutAdsEnabled = true

  return checkoutAdsEnabled ? (
    <Alert status='info' variant='left-accent'>
      <AlertIcon />
      <Link
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        // className='running-text'
        isExternal
        color='blue.500'
        fontSize={14}
        fontWeight={'bold'}
      >
        <Text>{description}</Text>
      </Link>
    </Alert>
  ) : (
    <></>
  )
}

export default Ad
// export default withLDConsumer()(Ad)
