import { CartProduct } from './cartproduct'

export enum PaymentMode {
  CashOnDelivery,
  Offline
}

export enum CartStatus {
  Pending,
  OnlinePayment,
  CartExpired,
  Completed,
  Failed,
  Abandoned,
  PendingForVerification,
  CashOnDelivery,
  PickUp,
  WaitingForPayment
}

export type PaymentInstruction = Array<{
  id: string
  title: string
  description: string
  image: string
}>

export interface Cart {
  editCartEnabled: boolean
  deliveryFee: number
  deliveryOptionId: any
  id: string
  cartStatus: CartStatus
  promotionDiscount: number
  total: number
  promotionDiscountPercentage: number
  promotionDiscountMinimumSpending: null | number
  subTotal: number
  pageName: string
  products: CartProduct[]
  pageId: string
  customer: Customer
  emailAddress: null | string
  phoneNumber: null | string
  deliveryAddress: null | string
  postalCode: null | string
  recipient: null | string
  deliveryInstruction: null | string
  promotionCode: null | string
  promotionId: null | string
  discount: number
  paymentProof: null | string
  currency: number
  permalinkUrl: string
  adsEnabled: boolean
  offlinePaymentEnabled: boolean
  onlinePaymentEnabled: boolean
  cashOnDeliveryPaymentEnabled: boolean
  customerVerificationRequired: boolean
  selfPickUpEnabled: boolean
  isVerified: boolean
  provinceId: number | null
  districtId: number | null
  wardCode: string
  giaoHangNhanhServiceId: number
  isJtExpressEnabled: boolean
  isGiaoHangNhanhEnabled: boolean
  extraNote: null | string
  jtCityId: null | string
  jtDistrictId: null | string
  jtAreaId: null | string
  kaioProvinceId: null | number
  kaioDistrictId: null | number
  kaioWardId: null | number
  organizationId: null | string
  isPaymeEnabled: boolean
}

export interface Customer {
  id: string
  name: string
  phoneNumber?: string
}

export interface BuyerData {
  id?: string
  email: null | string
  phoneNumber: null | string
  deliveryAddress: null | string
  postalCode: null | string
  recipient: null | string
  deliveryInstruction: null | string
  isDefault?: boolean
  districtId: number | null
  provinceId: number | null
  wardCode: string | null
}

export enum Currency {
  VND,
  AUD,
  USD,
  JPY
}

export interface ShippingSetting {
  id: string
  option: string
  surcharge: number
}

export interface GhnService {
  service_id: number
  short_name: string
  service_type_id: number
  total: number
}

export interface GhnProvince {
  provinceId: number
  provinceName: string
  countryId: string
  code: string
  isEnabled: boolean
  regionId: number
  nameExtension: string[]
}

export interface GhnWard {
  districtId: number
  wardCode: string
  wardName: string
}

export interface GhnDistrict {
  DistrictID: string
  ProvinceID: string
  districtName: string
  code: string
}

export interface JtCity {
  name: string
  id: string
}

export interface JtArea {
  name: string
  id: string
}

export interface JtDistrict {
  name: string
  id: string
}

export interface KaioProvince {
  name: string
  id: number
  code: string
}

export interface KaioDistrict {
  id: number
  name: string
  value: string
  provinceId: string
}

export interface KaioWard {
  id: number
  name: string
  districtId: string
}

export type UrlPaymePayload = {
  customerId: string | undefined
  merchantId: string | undefined
  orderId: string | undefined
  amount: number | undefined
  paymentMethod: string | undefined
}
